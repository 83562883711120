<template>
  <div class="searchbar-wrapper">
    <input type="text" v-model="searchQuery" @input="onInput" @keydown.down="onArrowDown" @keydown.up="onArrowUp"
      @keydown.enter="onEnter" @focus="showDropdown = true" class="searchbar" placeholder="Search for a project" />
    <ul v-if="showDropdown && filteredProjects.length > 0" class="dropdown">
      <li v-for="(project, index) in filteredProjects" :key="project.id"
        :class="{ 'highlighted': index === highlightedIndex }" @click="gotoProject(project)">
        <img v-if="project.thumbnail" :src="project.thumbnail" alt="Project Thumbnail" class="thumbnail" />
        {{ project.name }}
      </li>
    </ul>
    <loading-modal :modalStatus="loaderModalStatus" :project-id="selectedProjectId"
      @setModalStatus="setLoaderModalStatus">
    </loading-modal>
  </div>
</template>

<script>
import LoadingModal from '../Modals/LoadingModal.vue';
export default {
  components: {
    LoadingModal
  },
  data() {
    return {
      searchQuery: '',
      showDropdown: false,
      highlightedIndex: -1,
      searchTimeout: null,
      lastApiCallTime: 0,
      selectedProjectId: '',
      showLoaderModal: false,
    };
  },
  props: {
    compareFlag: {
      type: Boolean,
      required: false,
    },
    compareCallback: {
      type: Function,
      required: false
    }
  },
  methods: {
    onInput() {
      clearTimeout(this.searchTimeout);
      const now = Date.now();
      const timeSinceLastCall = now - this.lastApiCallTime;

      if (this.searchQuery.length >= 3) {
        if (timeSinceLastCall >= 1500) {
          this.fetchProjects();
        } else {
          this.searchTimeout = setTimeout(() => {
            this.fetchProjects();
          }, 1500 - timeSinceLastCall);
        }
      } else {
        this.showDropdown = false;
      }
    },
    async fetchProjects() {
      console.log("fetching projects...");
      this.lastApiCallTime = Date.now();
      try {
        await this.$store.dispatch('project/searchProjectByName', {
          limit: 10,
          query: this.searchQuery
        });
        this.showDropdown = true;
      } catch (err) {
        console.error(err);
      }
    },
    onArrowDown() {
      if (this.highlightedIndex < this.filteredProjects.length - 1) {
        this.highlightedIndex++;
      }
    },
    onArrowUp() {
      if (this.highlightedIndex > 0) {
        this.highlightedIndex--;
      }
    },
    onEnter() {
      if (this.highlightedIndex !== -1) {
        this.gotoProject(this.filteredProjects[this.highlightedIndex]);
      }
    },
    gotoProject(project) {
      this.showDropdown = false;
      this.highlightedIndex = -1;
      this.selectedProjectId = project._id
      if (this.compareFlag) {
        this.compareCallback();
        if (this.$route.path.includes('comparisson'))
          this.$router.push(`${project._id}`)
        else
          this.$router.push(`comparisson/${project._id}`)
        return
      }
      if (project.status === 'active') {
        this.setLoaderModalStatus(true);
      } else {
        this.$router.push({
          name: "project-analysis",
          params: {
            id: project._id
          }
        });
      }
    },
    setLoaderModalStatus(status) {
      this.showLoaderModal = status;
    },
  },
  computed: {
    filteredProjects() {
      const projects = this.$store.getters['project/getSearchedProjects'];
      if (this.compareFlag) {
        return projects.filter(project => project.status === 'closed');
      }
      return projects;
    },
    loaderModalStatus() {
      return this.showLoaderModal;
    },
  },
  mounted() {
    document.addEventListener('click', (e) => {
      if (!this.$el.contains(e.target)) {
        this.showDropdown = false;
      }
    });
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleClickOutside);
  }
}
</script>

<style scoped>
.searchbar-wrapper {
  position: relative;
  width: 100%;
}

.searchbar {
  width: 100%;
  height: 48px;
  padding-left: 15px;
  border: 1px solid lightgrey;
  border-radius: 4px;
  font-size: 16px;
  outline: none;
  box-shadow: none;
  transition: border-color 0.3s ease;
  border-radius: 20px;
  background-color: #EDF0F7;
}

.searchbar:focus {
  border-color: var(--emotiva-pink-light);
  outline: 0;
}

.dropdown {
  position: absolute;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: white;
  max-height: 200px;
  overflow-y: auto;
  margin-top: 8px;
  list-style: none;
  padding: 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.dropdown li {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.dropdown li.highlighted {
  background-color: var(--emotiva-pink-light);
  color: white;
}

.dropdown li:hover {
  background-color: #f1f1f1;
  color: #000;
}

.thumbnail {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  border-radius: 4px;
  object-fit: cover;
}
</style>
