<template>
	<b-card no-body style="background-color: transparent; border-color: transparent;" class="h-100 projectCard">
		<button class="no-decoration-btn" @click="openFolder">
			<img src="../../assets/general-icons/folder-icon-purple.svg" alt="Folder" class="folder__thumbnail">
		</button>
		<div class="options">

			<b-dropdown id="dropdown-1" no-caret size="md" variant="none" dropleft>
				<template #button-content style="background-color: transparent">
					<img src="../../assets/menu-icons/MENU_three_dots.svg" alt="" class="menu__icon" />
				</template>

				<b-dropdown-item @click="openRenameFolderModal()">{{
					$t("sideMenu.rename")
				}}
				</b-dropdown-item>


				<b-dropdown-item style="font-weight: bold;" class="delete-button" variant="none"
					@click="openDeleteFolderModal">
					<div class="delete-button">
						<img src="../../assets/general-icons/trash.svg" class="menu__icon" alt="">
						<span class="ml-2">{{ $t("sideMenu.delete") }}</span>
					</div>
				</b-dropdown-item>


			</b-dropdown>
		</div>
		<b-card-body class="pb-2 pt-1 px-1 info__section">
			<div class="">
				<h6 class="" :title="folderName" style="text-align: start;margin-top: -2px;">
					{{ folderName }}
				</h6>
				<p :title="folderDate" style="text-align: start; ">{{ $t("page.dashboard.project.added") }}: {{ folderDate
					}}</p>
			</div>
		</b-card-body>

		<generic-simple-input-modal :modalStatus="folderRenameModalStatus" :inputString="this.folder.name"
			:title="$t('sideMenu.renameFolder')" :bodyText="$t('name')" @setInputString="setNewFolderName"
			@setModalStatus="setFolderRenameModalStatus" @submit="renameFolder">
		</generic-simple-input-modal>
		<generic-yes-no-modal 
			:modalStatus="folderDeleteModalStatus" 
			:inputString="this.folder.name"
			:title="$t('sideMenu.deleteFolderTitle')"
			:bodyText="`${$t('sideMenu.deleteFolderText').replace('{{folderName}}', `<strong>${this.folder.name}</strong>`)} ?`" 
			variant="danger"
			:warning='$t("sideMenu.deleteFolderWarning")'
			@setModalStatus="setFolderDeleteModalStatus" 
			@submit="deleteFolder">
		</generic-yes-no-modal>

	</b-card>
</template>

<script>
import GenericSimpleInputModal from '../Modals/GenericSimpleInputModal.vue';
import GenericYesNoModal from '../Modals/GenericYesNoModal.vue';
export default {
	components: {
		GenericSimpleInputModal,
		GenericYesNoModal
	},
	data() {
		return {
			folderRenameModalStatus: false,
			folderDeleteModalStatus: false,
			newFolderName: "",
		}
	},
	props: {
		folder: {
			type: Object,
			required: true,
		}
	},
	methods: {
		openFolder() {
			this.$emit('folderChange', [this.folder]);
		},
		setFolderDeleteModalStatus(status) {
			this.folderDeleteModalStatus = status;
		},
		setFolderRenameModalStatus(status) {
			this.folderRenameModalStatus = status;
		},
		setNewFolderName(folderName) {
			this.newFolderName = folderName;
		},
		openRenameFolderModal() {
			this.selectedFolder = this.folder;
			this.folderRenameModalStatus = true;
		},
		openDeleteFolderModal() {
			this.selectedFolder = this.$createElementfolder;
			this.folderDeleteModalStatus = true;
		},
		renameFolder() {
			if (!this.folder) return;
			this.folder.name = this.newFolderName; // hack FIX IT
			this.$store.commit("loader/SET_LOADER", {});
			this.$store
				.dispatch("folder/updateFolderByClientId", this.folder)
				.then(() => {
					this.$store.dispatch("folder/retrieveFoldersByClientId");
				})
				.catch(() => { })
				.finally(() => this.$store.dispatch("loader/close"));
		},
		deleteFolder() {
			if (!this.folder) return;
			const folderId = this.folder._id;
			this.$store.commit("loader/SET_LOADER", {});
			this.$store
				.dispatch("folder/deleteFolderByClientId", folderId)
				.then(() => {
					this.$store.dispatch("folder/retrieveFoldersByClientId");
				})
				.catch(() => { })
				.finally(() => this.$store.dispatch("loader/close"));
		},
	},
	computed: {
		totalFiles() {
			return this.folder.projects.length || 0;
		},
		folderDate() {
			const localDate = new Date(this.folder.createdAt);
			return localDate.toLocaleDateString();
		},
		folderName() {
			return this.folder.name
		}
	}
}
</script>

<style lang="scss" scoped>
.projectCard {
	border-radius: 10px;
}

.folder__thumbnail {
	width: 100%;
	height: 150px;
	min-height: 150px;
	max-height: 150px;
	object-fit: contain;
	overflow: hidden;
}

.info__section {
	text-align: left; // Align text to the left
	min-height: 100px;

	h6 {
		white-space: nowrap; // Keep text to a single line
		overflow: hidden;
		text-overflow: ellipsis;
		font-weight: bold;
		position: relative;

		&:hover::after {
			content: attr(title); // Tooltip with full text on hover
			position: absolute;
			left: 0;
			top: 100%;
			background-color: rgba(0, 0, 0, 0.8);
			color: white;
			padding: 4px 8px;
			border-radius: 4px;
			font-size: 14px;
			white-space: normal;
			z-index: 1000;
			opacity: 0;
			visibility: hidden;
			transition: opacity 0.2s ease, visibility 0.2s ease;
		}

		&:hover::after {
			opacity: 1;
			visibility: visible;
		}
	}

	p {
		white-space: nowrap; // Keep text to a single line
		overflow: hidden;
		text-overflow: ellipsis;
		font-size: small;
		color: #6c757d;
		position: relative;

		&:hover::after {
			content: attr(title); // Tooltip with full text on hover
			position: absolute;
			left: 0;
			top: 100%;
			background-color: rgba(0, 0, 0, 0.8);
			color: white;
			padding: 4px 8px;
			border-radius: 4px;
			font-size: 14px;
			white-space: normal;
			z-index: 1000;
			opacity: 0;
			visibility: hidden;
			transition: opacity 0.2s ease, visibility 0.2s ease;
		}

		&:hover::after {
			opacity: 1;
			visibility: visible;
		}
	}
}

.folder__thumbnail,
.img__section {
	border-radius: 10px;
}

.info__section {
	text-align: center;
	min-height: 100px;
}

.options {
	position: absolute;
	width: 24px;
	height: 24px;
	background-color: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 5px;
	right: 6px;
	top: 100px;

}

.menu__icon {
	color: #2d3648;
	width: 16px;
}



.delete-button {
	width: 100%;
	border-radius: 5px;
}
</style>