<template>
  <div>
    <b-card class="border-left-0 border-right-0" >
      <b-row class="no-gutters justify-content-between">
        <!-- <b-col
          cols="12"
          lg="2"
          md="3"
          class="d-flex justify-content-center align-items-center"
          
        >
          <h1 class="title">
            {{ $t("mainMenu.projects") }}
          </h1>
        </b-col> -->
        <b-col cols="12" lg="8" md="12" xl="9" class="pr-5 pl-4">
          <SearchBar></SearchBar>
        </b-col>
        <b-col
          cols="12" lg="4" xl="3" md="12"
          class="d-flex justify-content-center align-items-center action-btn"
          v-if="isUserAllowedProjectHandling"
        >
          <b-button v-if="!isInsideFolder"
            @click="openFolderModal"
            class="mr-3 btn-blue-border justify-content-center align-items-center action-btn"
            variant="none"
            style="background-color: #fff;"
            ><img src="../../assets/general-icons/Icon-folder-add.svg" alt="" style="margin-top:-3px ;"/>
            <span class="ml-2">{{ $t("button.folder") }}</span></b-button
          >
          <b-button
            @click="hanldeCreateProjectAction"
            variant="none"
            style="color: #fff;"
            class="justify-content-center align-items-center action-btn emotiva-pink-background"
            ><img src="../../assets/general-icons/Icon-upload.svg" alt="" />
            <span class="ml-2">{{ $t("button.analysis") }}</span>
          </b-button>
        </b-col>
      </b-row>
    </b-card>
    <new-project-modal
      :modalStatus="modalStatus"
      :projectName="projectName"
      :projectType="projectType"
      :branchList="branchList"
      :projectBranchId="projectBranchId"
      :folderId="getFolderId"
      @setModalStatus="setModalStatus"
      @setProjectName="setProjectName"
      @submit="projectCreated"
    />

    <loading-modal
      :modalStatus="loaderModalStatus"
      :project-id="activeProjectId"
      @setModalStatus="setLoaderModalStatus"
    />

    <new-folder-modal
      :modalStatus="folderModalStatus"
      :folderName="folderName"
      @setFolderName="setFolderName"
      @setModalStatus="setFolderModalStatus"
      @submit="createFolder"
    />
    
  </div>
</template>

<script>
import dayjs from "dayjs";
import NewProjectModal from "./../NewProject/NewProjectModal";
import SearchBar from "./SearchBar.vue";
import NewFolderModal from "../Modals/NewFolderModal.vue";
import LoadingModal from "../Modals/LoadingModal.vue";
export default {
  components: {
    NewProjectModal,
    SearchBar,
    NewFolderModal,
    LoadingModal,
  },
  data() {
    return {
      visible: true,
      modalStatus: false,
      folderModalStatus:false,
      folderName:"",
      folderDescription:'',
      projectName: "",
      projectBranchId: "",
      projectType: "custom",
      showLoaderModal:false,
      activeProjectId:''
    };
  },
  props:{
    isInsideFolder:{
      type:Boolean,
      required:true,
    },
    folderId:{
      type:String,
      required:true,
    }
  },
  computed: {
    loaderModalStatus(){
      return this.showLoaderModal;
    },
    userType() {
      return this.$store.getters["auth/getUserType"];
    },
    userSubType() {
      return this.$store.getters["auth/getUserSubType"];
    },
    isMultiUser() {
      return this.$store.getters["auth/isMultiUser"];
    },
    getFolderId(){
      if(this.isInsideFolder)
        return this.folderId
      return ''
    },
    branchList() {
      // if custom multiuser admin users return branch list
      if (
        this.userType === "custom" &&
        this.isMultiUser &&
        this.userSubType === "admin"
      ) {
        return this.$store.getters["multiuser/getBranches"];
      }
      return [];
    },
    audienceProjectCategories() {
      return this.$store.getters["audience/getBrandCategoryOptions"];
    },
    isUserAllowedProjectHandling() {
      // custom + multiuser + researchers are not allowed to create, edit, delete a project
      if (
        this.userType === "custom" &&
        this.isMultiUser &&
        this.userSubType === "researcher"
      ) {
        return false;
      } else {
        return true;
      }
    },
  },
  methods: {
    hanldeCreateProjectAction() {
      this.openModal();
    },
    setLoaderModalStatus(status){
      this.showLoaderModal = status;
    },
    async projectCreated(){
      const projectTemp = this.$store.getters['project/getActiveProject']
      await this.$store.dispatch('project/retrieveProjectByClientId',projectTemp._id); 
      const project =  this.$store.getters['project/getActiveProject'];
      this.activeProjectId=project._id
      this.openLoaderModal();
      this.$store.commit('project/ADD_PROJECT_TO_LIST',project);
      if(this.isInsideFolder){
        this.$store.commit('folder/ADD_PROJECT_TO_LIST',project)
      }
    },
    openLoaderModal(){
      this.showLoaderModal = true;
    },
    resetFolder(){
      this.folderDescription='';
      this.folderName='';
    },
    createFolder() {
      const payload = {
        name:this.folderName,
        description:this.folderDescription
      }
      // this.$store.commit("folder/SET_ACTIVE_FOLDER",{})
      this.$store
        .dispatch("folder/createFolderByClientId",payload)
        .then(() => {
          this.$store.dispatch("folder/retrieveFoldersByClientId");
        })
        .catch(()=>{})
        .finally(()=>this.$store.dispatch("loader/close"))
      this.resetFolder()
    },
    openModal() {
      this.modalStatus = true;
      this.projectName =
        this.projectType.toUpperCase() +
        " - " +
        this.$t("page.projects.newProject") +
        ` ${dayjs().format("DD/MM/YYYY, HH:mm:ss")}`;
    },
    openFolderModal(){
      this.folderModalStatus=true;
    },
    setFolderName(folderName){
      this.folderName=folderName
    },
    setFolderModalStatus(modalStatus){
      this.folderModalStatus=modalStatus;
    }, 
    setModalStatus(modalStatus) {
      this.modalStatus = modalStatus;
    },
    setProjectName(projectName) {
      this.projectName = projectName;
    },
    setProjectBranchId(projectBranchId) {
      this.projectBranchId = projectBranchId;
    },
  },
};
</script>

<style scoped>
.card-wrapper {
  background-color: #fff;
}
.btn__font-style {
  /* font-family: ; */
  font-size: 0.8rem;
  font-weight: 600;
}
.title {
  padding: 0;
  margin: 0;
  font-weight: bold;
}
.btn-blue-border {
  border: 1px solid #717D96; 
  color: #717D96;
  font-weight: 700;
}

.action-btn {
  height: 46px;
  border-radius: 20px; 
  
}

@media (max-width: 992px) {
  .action-btn {
    margin-top: 10px;
  }
}
</style>
