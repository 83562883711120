<template>

  <b-card no-body style="background-color: transparent; border-color: transparent;" class="h-100 projectCard">
    <button class="no-decoration-btn" @click="openProject">
      <img v-if="projectThumbnail" class="project__thumbnail" :src="projectThumbnail" />
      <div v-else class="img__section"></div>
    </button>
    <div class="viewer-icon">
      <img :src="projectIcon" alt="" class="small-image">
    </div>
    <div class="options">

      <b-dropdown id="dropdown-1" no-caret size="md" variant="none" dropleft>
        <template #button-content style="background-color: transparent">
          <img src="../../assets/menu-icons/MENU_three_dots.svg" alt="" class="menu__icon" />
        </template>

        <b-dropdown-item @click="openRenameModal()">{{
          $t("sideMenu.rename")
        }}
        </b-dropdown-item>
        <b-dropdown id="dropdown-2" variant="white" no-caret dropleft size="md">
          <template #button-content>
            <img src="../../assets/menu-icons/MENU_folder.svg" class="dropdown-icon" />
            <span class="ml-2">{{ !isInsideFolder ? $t("page.dashboard.project.addProjectToFolder")
              : $t("page.dashboard.project.moveProjectToAnotherFolder")}}</span>
          </template>
          <b-dropdown-item @click="addProjectToFolder(folder)" v-for="(folder, index) in folderList" :key="index" >
            <img src="../../assets/menu-icons/MENU_folder.svg" class="dropdown-icon" />
           <span class="ml-2">
             {{ folder.name }}
           </span>
          </b-dropdown-item>


        </b-dropdown>
        <b-dropdown-item v-if="isInsideFolder" @click="removeProjectFromFolder()">
          <b-icon icon="x" variant="none" font-scale="1.5" style="margin-left: -5px;"></b-icon>
          <span>
            {{ $t("page.dashboard.project.removeProjectFromFolder") }}
          </span>
        </b-dropdown-item>

        <b-dropdown-item style="font-weight: bold;" class="delete-button" variant="none"
          @click="openDeleteConfirmationDialog">
          <div class="delete-button">
            <img src="../../assets/general-icons/trash.svg" class="menu__icon" alt="">
            <span class="ml-2">{{ $t("sideMenu.delete") }}</span>
          </div>
        </b-dropdown-item>


      </b-dropdown>
    </div>
  
    <b-card-body class="pb-2 pt-1 px-1 info__section">

      <div class="mt-2">
        <h6 class="mt-3">
          {{ project.name }}
        </h6>
        <p>{{ $t("page.dashboard.project.added") }}: {{ projectDate }}</p>
      </div>

    </b-card-body>

    <dummy-url-modal v-if="showProjectTestUrls" :state="dummyModalState" :stimulusList="dummyStimulusList"
      @updateState="updateDummyModalState" />
    <generic-simple-input-modal :modalStatus="renameProjectModal" :inputString="this.project.name"
      :title="$t('sideMenu.rename')" :bodyText="$t('name')" @setInputString="setNewProjectName"
      @setModalStatus="setRenameProjectModal" @submit="renameProject">
    </generic-simple-input-modal>
    <loading-modal
      :modalStatus="loaderModalStatus"
      :project-id="project._id"
      @setModalStatus="setLoaderModalStatus"
    >
    </loading-modal>
  </b-card>
</template>

<script>
import Utils from "./../../Utils";
import Config from "./../../plugins/Config";
import DummyURLModal from "./../Dashboard/DummyURLModal";
import GenericSimpleInputModal from "../Modals/GenericSimpleInputModal.vue";
import LoadingModal from "../Modals/LoadingModal.vue";
export default {
  components: {
    LoadingModal,
    GenericSimpleInputModal,
    "dummy-url-modal": DummyURLModal,
  },
  props: {
    project: {
      type: Object,
      required: true
    },
    projectStatus: {
      type: String,
      required: true
    },
    isInsideFolder:{
      type:Boolean,
      required:true,
    },
  },
  data() {
    return {
      contextData: {},
      dummyModalState: false,
      dummyStimulusList: [],
      renameProjectModal: false,
      deleteProjectConfirmModal: false,
      newProjectName: "",
      selectedProject: {},
      showLoaderModal:false,
    };
  },
  mounted() {
    this.$root.$on('bv::dropdown::show', bvEvent => {
      if (bvEvent.componentId === 'dropdown-2') {
        this.isDropdown2Visible = true;
      }
    })
    this.$root.$on('bv::dropdown::hide', bvEvent => {
      if (bvEvent.componentId === 'dropdown-2') {
        this.isDropdown2Visible = false;
      }
      if (this.isDropdown2Visible) {
        bvEvent.preventDefault()
      }
    })
  },
  computed: {
    loaderModalStatus(){
      return this.showLoaderModal;
    },
    projectDataType(){
      return this.$store.getters["analysisV2getProjectDataType/getProjectDataType"];
    },
    projectIcon() {
      // return require(`../../assets/viewer-icons/instagram-viewer-mini.svg`)
      if(this.project.viewer === 'standard' && this.project.predictMediaType && this.project.predictMediaType==='image')
        return require(`../../assets/viewer-icons/${this.project.viewer}-viewer-mini-image.svg`)
      return require(`../../assets/viewer-icons/${this.project.viewer}-viewer-mini.svg`)
    },
    
    isClosed() {
      return this.projectStatus === Utils.PROJECT_STATUS.CLOSED;
    },
    projectDate() {
      const localDate = new Date(this.project.createdAt);
      return localDate.toLocaleDateString();
    },
    projectViewerIcon() {
      return this.project.viewer;
    },
    isComplete(){
      return this.project.status==='closed'
    },
    actionIcon() {
      switch (this.projectStatus) {
        case Utils.PROJECT_STATUS.CLOSED:
          return "dashboard-web";
        case Utils.PROJECT_STATUS.PENDING:
        case Utils.PROJECT_STATUS.ACTIVE:
        default:
          return "options";
      }
    },
    actionText() {
      switch (this.projectStatus) {
        case Utils.PROJECT_STATUS.PENDING:
          return "configPending";
        case Utils.PROJECT_STATUS.ACTIVE:
          return "analyzing";
        case Utils.PROJECT_STATUS.CLOSED:
          return "viewDashboard";
        default:
          return "";
      }
    },
    folderList() {
      const folderList = this.$store.getters["folder/getFolderList"];
      const sortedFolderList = folderList.slice().sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      });
      return sortedFolderList;
    },
    
    projectCreatedDate() {
      return this.project.createdAt;
      // return "2020-11-01";
    },
    projectClosedDate() {
      return this.project.closedDate;
    },
    projectThumbnail() {
      return this.project.thumbnail;
    },
    isProjectActive() {
      return this.project.status === "active";
    },
    isProjectPending() {
      return this.project.status === Utils.PROJECT_STATUS.PENDING;
    },
    showProjectTestUrls() {
      return Config.value("showProjectTestUrls") === "true";
    },
    projectType() {
      if (this.isClientCustom) {
        return "custom";
      }
      return this.project.type;
    },
    isClientCustom() {
      return (
        this.$store.getters["auth/getUserType"] === Utils.USER_TYPE.CUSTOMER
      );
    },
    userSubType() {
      return this.$store.getters["auth/getUserSubType"];
    },
    client() {
      return { ...this.$store.getters["auth/getUserAccount"] };
    },
    isMultiUser() {
      return this.$store.getters["auth/isMultiUser"];
    },
    showBranchInfo() {
      if (this.isClientCustom && this.isMultiUser) {
        if (this.userSubType === Utils.USER_SUBTYPE.ADMIN) {
          return true;
        } else if (this.client.shareProjects) {
          return true;
        }
      }
      return false;
    },
    isUserAllowedProjectHandling() {
      // custom + multiuser + researchers are not allowed to create, edit, delete a project
      if (
        this.isClientCustom &&
        this.isMultiUser &&
        this.userSubType === Utils.USER_SUBTYPE.RESEARCHER
      ) {
        return false;
      } else {
        return true;
      }
    },
    branch() {
      const branches = this.$store.getters["multiuser/getBranches"];
      return branches.find((branch) => {
        return branch._id === this.project.branchId;
      });
    },
  },
  methods: {
    updateDummyModalState(val) {
      this.dummyModalState = val;
    },
    openDummyModal() {
      this.$store
        .dispatch("project/dummyRetrieveProjectAssets", {
          projectId: this.project._id
        })
        .then(urlList => {
          this.dummyStimulusList = urlList;
          this.dummyModalState = true;
        })
        .catch(() => { })
        .finally(() => { });
    },
    openProject() {
      if (this.project.status === "pending") this.redirectToWizard();
      else if (this.project.status==='active') {
        this.openLoaderModal();
      }
      else if (this.project.status === "closed") {
        this.$router.push({
          name: "project-analysis",
          params: {
            id: this.project._id
          }
        });
      } else {
        this.$store.commit("error/SET_ERROR", {
          titleText: "dialog.error.defaultTitle",
          messageText: "dialog.error.defaultMessage"
        });
      }
    },
    openLoaderModal(){
      this.showLoaderModal = true;
    },
    setLoaderModalStatus(status){
      this.showLoaderModal = status;
    },
    async redirectToWizard() {
      // try {
      //   const activeProjectId = this.$store.getters[
      //     "project/getActiveProjectId"
      //   ];
      //   const currentProjectId = this.project._id;
      //   if (currentProjectId !== activeProjectId) {
      //     this.$store.commit("loader/SET_LOADER", {});
      //     await this.$store.dispatch(
      //       "project/retrieveProjectByClientId",
      //       currentProjectId
      //     );
      //     await this.$store.dispatch("stimulus/retrieveProjectAssets");
      //     this.$store.dispatch("loader/close");
      //   }
      //   this.$store.commit("wizard/SET_ACTIVE_STEP", this.project.step);
      //   const wizardNextRouteName = this.$store.getters[
      //     "wizard/getCurrentRouteName"
      //   ];
      //   this.$router.push({
      //     name: wizardNextRouteName,
      //     params: { id: currentProjectId }
      //   });
      // } catch (isAuthorizationError) {
      //   if (!isAuthorizationError) {
      //     this.$store.commit("error/SET_ERROR", {
      //       messageText: "dialog.error.pageNotFoundMessage"
      //     });
      //   }
      //   this.$store.dispatch("loader/close");
      // }
    },
    openDeleteConfirmationDialog() {
      this.$store.commit("confirm/SET_CONFIRM", {
        titleText: "dialog.confirm.deleteProjectTitle",
        messageText: "dialog.confirm.deleteProjectMsg",
        messageParams: { projectName: this.project.name },
        confirmBtnCallback: this.deleteProject,
        type: "DELETE"
      });
    },
    deleteProject() {
      this.$store.commit("loader/SET_LOADER", {});
      this.$store
        .dispatch("project/deleteProjectByClientId", this.project._id)
        .then(()=>{
          this.$store.commit('folder/REMOVE_PROJECT_FROM_LIST',this.project._id)
          this.$store.commit('project/REMOVE_PROJECT_FROM_LIST',this.project._id)
        })
        .catch(() => { })
        .finally(() => {
          this.$store.dispatch("loader/close");
        });
    },
    renameProject() {
      this.project.name = this.newProjectName;
      this.$store.commit("loader/SET_LOADER", {});
      this.$store.dispatch("project/updateProjectByClientIdV2", this.project)
        .then(() => {
          // this.$store.dispatch("project/retrieveProjectsByClientId");
        }).catch(() => { })
        .finally(() => this.$store.dispatch("loader/close"));
    },
    addProjectToFolder(folder) {
      const folderId = folder._id
      const projectId= this.project._id
      const payload ={
        folderId:folderId,
        projectId:projectId,
      }
      
      this.$store.dispatch("folder/addProjectToFolder", payload)
        .then(() => {
          if (this.isInsideFolder) {
            // this.$store.commit('folder/REMOVE_PROJECT_FROM_LIST',this.project._id)
            const isChangingFolders = true
            this.removeProjectFromFolder(isChangingFolders);
          }else{
            this.$store.commit('project/REMOVE_PROJECT_FROM_LIST',this.project._id)
            this.$store.commit('folder/SET_ACTIVE_FOLDER',{})
            this.$store.commit('folder/SET_PROJECT_LIST',[])

          }
        }).catch((error) => {
          console.log("Error while adding project to folder: ", error);
        })
    },
    removeProjectFromFolder(isChangingFolders=false) {
      const folder = this.$store.getters['folder/getActiveFolder']
      const folderId = folder._id;
      const projectId= this.project._id
      const payload = {
        folderId:folderId,
        projectId:projectId
      }
      this.$store.dispatch("folder/removeProjectFromFolder", payload)
        .then(() => {
          if (this.isInsideFolder) {
            this.$store.commit('folder/REMOVE_PROJECT_FROM_LIST',this.project._id)
            if(!isChangingFolders)
              this.$store.commit("project/ADD_PROJECT_TO_LIST",this.project)
          }
        }).catch((error) => {
          console.log("Error while adding project to folder: ", error);
        })
    },
    // Modal functions
    openRenameModal() {
      this.renameProjectModal = true;
    },
    setNewProjectName(projectName) {
      this.newProjectName = projectName;
    },
    setRenameProjectModal(status) {
      this.renameProjectModal = false;
    },
    setDeleteProjectConfirmModal(status) {
      this.deleteProjectConfirmModal = status;
    },

  }
};
</script>

<style lang="scss" scoped>
.projectCard {
  border-radius: 10px;
}

.project__thumbnail {
  width: 100%;
  height: 132px;
  object-fit: cover;
  overflow: hidden;
}

.img__section {
  height: 132px;
  width: 100%;
  background-color: var(--red);
}

.project__thumbnail,
.img__section {
  border-radius: 10px;
}

.info__section {
	text-align: left; // Align text to the left
	min-height: 100px;

	h6 {
		white-space: nowrap; // Keep text to a single line
		overflow: hidden;
		text-overflow: ellipsis;
		font-weight: bold;
		position: relative;

		&:hover::after {
			content: attr(title); // Tooltip with full text on hover
			position: absolute;
			left: 0;
			top: 100%;
			background-color: rgba(0, 0, 0, 0.8);
			color: white;
			padding: 4px 8px;
			border-radius: 4px;
			font-size: 14px;
			white-space: normal;
			z-index: 1000;
			opacity: 0;
			visibility: hidden;
			transition: opacity 0.2s ease, visibility 0.2s ease;
		}

		&:hover::after {
			opacity: 1;
			visibility: visible;
		}
	}

	p {
		white-space: nowrap; // Keep text to a single line
		overflow: hidden;
		text-overflow: ellipsis;
		font-size: small;
		color: #6c757d;
		position: relative;

		&:hover::after {
			content: attr(title); // Tooltip with full text on hover
			position: absolute;
			left: 0;
			top: 100%;
			background-color: rgba(0, 0, 0, 0.8);
			color: white;
			padding: 4px 8px;
			border-radius: 4px;
			font-size: 14px;
			white-space: normal;
			z-index: 1000;
			opacity: 0;
			visibility: hidden;
			transition: opacity 0.2s ease, visibility 0.2s ease;
		}

		&:hover::after {
			opacity: 1;
			visibility: visible;
		}
	}
}

.action__icon {
  width: 20px;
  filter: invert(49%) sepia(7%) saturate(539%) hue-rotate(167deg) brightness(90%) contrast(88%);
}

.delete__icon {
  width: 16px;
  filter: invert(49%) sepia(7%) saturate(539%) hue-rotate(167deg) brightness(90%) contrast(88%);
}

.options,
.viewer-icon {
  position: absolute;
  width: 24px;
  height: 24px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.viewer-icon {
  right: 6px;
  top: 6px;
}

.small-image {
  width: 16px;
  height: 16px;
}

.menu__icon {
  color: #2d3648;
  width: 16px;
}

.options {
  right: 6px;
  top: 100px;
}


.dropdown-icon {
  width: 18px;
  height: 18px;
  margin-left: 10px;
}


.delete-button {
  width: 100%;
  border-radius: 5px;
}
</style>
