<template>
  <div>
    <b-container v-if="userHasProjects || userHasFolders" class="pt-3 infinite-scroll-container pt-5" >

      <!-- In progress projects -->
      <div v-if="inProgressProjects.length > 0">
        <div class="title__1">
          {{ $t(`page.dashboard.project.status.active.title`) }}
        </div>
        <p class="text-muted subtitle__1">
          {{
            $t(
              `page.dashboard.project.status.active.description`
            )
          }}
        </p>

      </div>
      <b-row class="">
        <b-col xl="2" lg="2" md="3" sm="6" v-for="project in inProgressProjects" :key="project.projectId"
          class="mt-1 mb-3 project__list__item">
          <project-dashboard-list-item :project="project" :projectStatus="project.status" :isInsideFolder="isInsideFolder"/>
        </b-col>
      </b-row>
      <!-- In progress projects -->


      <!-- Completed projects -->
      <div v-if="completedProjects.length > 0">
        <div class="title__1">
          {{ $t(`page.dashboard.project.status.closed.title`) }}
        </div>
        <p class="text-muted subtitle__1">
          {{
            $t(
              `page.dashboard.project.status.closed.description`
            )
          }}
        </p>
      </div>
      <b-row>
        <b-col xl="2" lg="3" md="4" sm="6" v-for="folder in folderList" :key="folder._id"
          class="mt-1 mb-3 project__list__item">
          <folder-dashboard-list-item :folder="folder" @folderChange="navigateToFolder(folder)" />
        </b-col>

        <b-col xl="2" lg="3" md="4" sm="6" v-for="(project, index) in completedProjects" :key="index"
          class="mt-1 mb-3 project__list__item">
          <project-dashboard-list-item :project="project" :projectStatus="project.status" :isInsideFolder="isInsideFolder"/>
        </b-col>
        <div class="spinner-wrapper pt-2 pb-5">
          <div v-if="isLoadingCompleteProjects" class="spinner"></div>
        </div>
        <div ref="observerTarget" class="observer-target">{{ noMoreProjectsToLoad }}</div>
      </b-row>
      <!-- Completed projects -->

    </b-container>


    <div v-else class="mt-5 text-center" style="color:var(--emotiva-white-text)">
      <h3 class="mb-3">{{ $t("page.projects.noPublishedProjects.title") }}</h3>
      <div v-if="isUserAllowedProjectHandling">
        <h6 class="mb-0" style="font-weight: 300">
          {{ $t("page.projects.noPublishedProjects.subtitle-1") }}
        </h6>
        <h6 class="mb-0" style="font-weight: 300">
          {{ $t("page.projects.noPublishedProjects.subtitle-2") }}
        </h6>
        <b-button class="justify-content-center align-items-center bold emotiva-pink-background action-btn mt-5"
          size="sm" variant="none" style="color: #fff;" @click="hanldeCreateProjectAction">
          <img src="../../assets/general-icons/startAnalysis-icon.svg" alt="" />
          <span class="ml-2">{{ $t("button.analysis") }}</span>

        </b-button>
      </div>
    </div>
    <new-project-modal :modalStatus="modalStatus" :projectName="projectName" :projectType="projectType"
      :branchList="isMultiuserAdmin ? branchList : []" :projectBranchId="projectBranchId"
      @setModalStatus="setModalStatus" @setProjectName="setProjectName" @setProjectBranchId="setProjectBranchId" />
  </div>
</template>

<script>
import Utils from "./../../Utils";
import ProjectDashboardListItem from "./ProjectDashboardListItem";
import NewProjectModal from "./../NewProject/NewProjectModal";
import FolderDashboardListItem from "./FolderDashboardListItem.vue";
export default {
  components: {
    ProjectDashboardListItem,
    NewProjectModal,
    FolderDashboardListItem,
  },
  data() {
    return {
      modalStatus: false,
      projectName: "",
      projectBranchId: "",
      projectType: "custom",
      observer: null,
    };
  },
  props: {
    projectList: {
      type: Array,
      required: true
    },
    folderList: {
      type: Array,
      required: true,
    },
    isInsideFolder:{
      type:Boolean,
      required:true,
    },
    userHasProjects: {
      type: Boolean,
      required: true
    },
    userHasFolders:{
      type:Boolean,
      required:true
    },
    isLoadingCompleteProjects: {
      type: Boolean,
      required: true,
    },
    isLoadingInProgressProjects: {
      type: Boolean,
      required: true,
    },
    filterType: {
      type: String,
      required: true,
    },
    noMoreProjectsToLoad: {
      type: Boolean,
      required: true,
    },
   

  },
  computed: {
    userType() {
      return this.$store.getters["auth/getUserType"];
    },
    userSubType() {
      return this.$store.getters["auth/getUserSubType"];
    },
    isMultiUser() {
      return this.$store.getters["auth/isMultiUser"];
    },
    serachWord() {
      return this.$store.getters["project/getSearchWord"];
    },
    filteredCompletedProjectList() {
      let projectList = this.projectList[2].list;
      projectList = projectList.filter((project) =>
        project.name.toLowerCase().includes(this.serachWord.toLowerCase())
      );
      return projectList;
    },
    completedProjects() {
      return this.projectList[2].list
    },
    

    isMultiuserAdmin() {
      if (
        this.userType === Utils.USER_TYPE.CUSTOMER &&
        this.isMultiUser &&
        this.userSubType === Utils.USER_SUBTYPE.ADMIN
      ) {
        return true;
      } else {
        return false;
      }
    },
    branchList() {
      return this.$store.getters["multiuser/getBranches"];
    },
    hasProjects() {
      if (this.$route.name === "Folder") {
        return true;
      }
      let hasEntries = false;
      for (const key in this.projectList) {
        if (this.projectList[key].list.length > 0) {
          hasEntries = true;
          break;
        }
      }
      return hasEntries;
    },
    isUserAllowedProjectHandling() {
      // custom + multiuser + researchers are not allowed to create, edit, delete a project
      if (
        this.userType === Utils.USER_TYPE.CUSTOMER &&
        this.isMultiUser &&
        this.userSubType === Utils.USER_SUBTYPE.RESEARCHER
      ) {
        return false;
      } else {
        return true;
      }
    },
    inProgressProjects() {
      return this.projectList[1].list
    }
  },
  mounted() {
    if (this.userHasProjects)
      this.createObserver()
  },
  methods: {
    loadMoreCompleteProjects() {
      if(this.noMoreProjectsToLoad){
        return
      }
      if (this.isLoadingCompleteProjects)
        return
      if (this.filterType === 'folders')
        return
      this.$emit("loadMoreCompleteProjects")
    },
    createObserver() {
      this.observer = new IntersectionObserver(
        (entry) => {
          if (entry[0].isIntersecting) {
            this.loadMoreCompleteProjects()
            
          } else if (!entry[0].isIntersecting) {
          }
        },
        {
          threshold: [1]
        }
      )
      this.observer.observe(this.$refs.observerTarget);
    },
    destroyObserver() {
      if (this.observer) this.observer.disconnect();
    },
    navigateToFolder(folder) {
      // Emit the new folder path to the parent component
      this.$emit('folderChange', [folder]);
    },

    isListEmpty(list) {
      return list.length === 0
    },
    isActiveProjectList(status) {
      return status === Utils.PROJECT_STATUS.ACTIVE;
    },
    hanldeCreateProjectAction() {
      this.openModal();
    },
    openModal() {
      this.modalStatus = true;
    },
    setModalStatus(modalStatus) {
      this.modalStatus = modalStatus;
    },
    setProjectName(projectName) {
      this.projectName = projectName;
    },
    setProjectBranchId(projectBranchId) {
      this.projectBranchId = projectBranchId;
    },
    includesSearchWord(project) {
      if (this.serachWord === "") return true;
      return project.name.includes(this.serachWord);
    },
  },
};
</script>

<style scoped>

.project__list__item {
  width: 190px;
  max-width: 190px;
  /* min-height: 131px; */
}

.create-project-btn {
  font-size: 0.8rem;
  font-weight: 600;
}

.bold {
  font-weight: bold;
}

.action-btn {
  border-radius: 26px;
  height: 46px;
  min-width: 100px;
}

.spinner-wrapper {
  width: 100%;

}

.spinner {
  width: 150px;
  height: 150px;
  border: 15px solid #c3c3f3;
  border-top: 15px solid #6f14ef;
  border-radius: 50%;
  animation: spin 1.5s forwards infinite;
  margin: 0 auto;
}

.observer-target {
  height: 30px;
  width: 100%;
  z-index: 99;
  margin-top: -25%;
  background-color: red;
  visibility: hidden;
}

.infinite-scroll-container {
  overflow-y: auto;
  height: 75vh;
  margin-bottom: 20px;

  /* Hide scrollbar for Chrome, Safari, and Edge */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
}

.infinite-scroll-container::-webkit-scrollbar {
  display: none; /* Chrome, Safari, and Edge */
}



@media screen and (max-width: 400px) {
  .project__list__item {
    min-width: unset;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
