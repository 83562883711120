<template>
  <nav class="navigation-bar pl-5 pr-5">
    <div class="nav-left">
      <span class="home-icon link-underlined">
        <img src="./../../assets/menu-icons/MENU_home.svg" alt="🏠" class="menu__icon">
      </span>
      <span class="ml-2 link-underlined" @click="goToFolder(0)">{{ $t('home') }}</span>
      <span v-for="(folder, index) in folderPath.slice(1)" :key="index" class="folder">
        <span class="separator"> > </span>
        <span class="folder-name link-underlined" @click="goToFolder(index + 1)">{{ folder.name }}</span>
      </span>
    </div>

    <div class="nav-right" v-if="!isInsideFolder">
      <button @click="filterFolders" :class="{'active': currentFilter === 'folders'}" class="nav-button">{{ $t('page.dashboard.showOnlyFolders') }}</button>
      <button @click="filterProjects" :class="{'active': currentFilter === 'projects'}" class="nav-button">{{ $t('page.dashboard.showOnlyprojects') }}</button>
      <button @click="showAll" :class="{'active': currentFilter === 'all'}" class="nav-button">{{ $t('page.dashboard.showAll') }}</button>
    </div>
  </nav>
</template>


<script>
export default {
  props: {
    folderPath: {
      type: Array,
      required: true
    },
    isInsideFolder:{
      type:Boolean,
      required:true,
    }
  },
  data() {
    return {
      currentFilter: 'all' // default filter state
    };
  },
  methods: {
    goToFolder(index) {
      // Emit the path up to the clicked folder index
      this.$emit('navigateToFolder', this.folderPath.slice(0, index + 1));
    },
    filterFolders() {
      this.currentFilter = 'folders';
      this.$emit('filterContent', 'folders');
    },
    filterProjects() {
      this.currentFilter = 'projects';
      this.$emit('filterContent', 'projects');
    },
    showAll() {
      this.currentFilter = 'all';
      this.$emit('filterContent', 'all');
    }
  }
};
</script>


<style scoped>
.navigation-bar {
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
}

.nav-left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.link-underlined:hover {
  text-decoration: underline;
}

.link-underlined {
  cursor: pointer;
}

.home-icon {
  font-size: 1.5rem;
  align-self: center;
}

.folder {
  display: flex;
  align-items: center;
}

.separator {
  margin: 0 0.5rem;
}

.folder-name {
  font-weight: bold;
}

.menu__icon {
  color: #2d3648;
  width: 24px;
  height: 24px;
  margin-top: -4px;
}

.nav-right {
  display: flex;
  align-items: center;
}

.nav-button {
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 6px;
  padding: 0.5rem 1rem;
  margin-left: 1rem;
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s;
}

.nav-button:hover {
  background-color: #ddd;
}

.nav-button.active {
  background-color: lightgray;
}
</style>
